import { Contract } from 'ethers';
import { useContext } from 'react';

import BlochainContext from '../../components/context/blockchain-provider';
import erc721 from './erc721-abi';

export const useERC721Contract = (nftAddress: string) => {
    const { abi } = erc721;
    const { provider } = useContext(BlochainContext);
    if (!provider) return { contract: null };
    const signer = provider.getSigner();
    const ERC721Contract = new Contract(nftAddress, abi, signer);
    const erc721Contract = ERC721Contract.connect(signer);
    return { erc721Contract };
};
