const marketplaceContract = {
    address: '0xa5f7acb035818fc9653ebd5e93649a4c98412398',
    abi: [
        {
            inputs: [
                {
                    internalType: 'address payable',
                    name: 'newBeneficiary',
                    type: 'address',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
            ],
            name: 'AcceptAsk',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                },
            ],
            name: 'AcceptBid',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
            ],
            name: 'CancelAsk',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
            ],
            name: 'CancelBid',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
            ],
            name: 'CreateAsk',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'nft',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                },
            ],
            name: 'CreateBid',
            type: 'event',
        },
        {
            inputs: [],
            name: 'REVERT_ASK_DOES_NOT_EXIST',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_ASK_INSUFFICIENT_VALUE',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_ASK_IS_RESERVED',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_ASK_SELLER_NOT_OWNER',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_BID_TOO_LOW',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_CANT_ACCEPT_OWN_ASK',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_INSUFFICIENT_ETHER',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_NFT_NOT_SENT',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_NOT_A_CREATOR_OF_ASK',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_NOT_A_CREATOR_OF_BID',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_NOT_OWNER_OF_TOKEN_ID',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'REVERT_OWNER_OF_TOKEN_ID',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
            ],
            name: 'acceptAsk',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
            ],
            name: 'acceptBid',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'asks',
            outputs: [
                { internalType: 'bool', name: 'exists', type: 'bool' },
                { internalType: 'address', name: 'seller', type: 'address' },
                { internalType: 'uint256', name: 'price', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                { internalType: 'address', name: '', type: 'address' },
                { internalType: 'uint256', name: '', type: 'uint256' },
            ],
            name: 'bids',
            outputs: [
                { internalType: 'bool', name: 'exists', type: 'bool' },
                { internalType: 'address', name: 'buyer', type: 'address' },
                { internalType: 'uint256', name: 'price', type: 'uint256' },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
            ],
            name: 'cancelAsk',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
            ],
            name: 'cancelBid',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address payable',
                    name: 'newBeneficiary',
                    type: 'address',
                },
            ],
            name: 'changeBeneficiary',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
                { internalType: 'uint256[]', name: 'price', type: 'uint256[]' },
                { internalType: 'address[]', name: 'to', type: 'address[]' },
            ],
            name: 'createAsk',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'contract INFTContract[]',
                    name: 'nft',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: 'tokenID',
                    type: 'uint256[]',
                },
                { internalType: 'uint256[]', name: 'price', type: 'uint256[]' },
            ],
            name: 'createBid',
            outputs: [],
            stateMutability: 'payable',
            type: 'function',
        },
        {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'escrow',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'revokeAdmin',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
    ],
};

export default marketplaceContract;
