import { Contract } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { toWei } from 'web3-utils';

import BlochainContext from '../../components/context/blockchain-provider';
import { EVENT_TYPE } from '../../models/event-type';
import { AskPrice, BidPrice, OPERATION_TYPES } from '../../models/marketplace';
import { EventEmitter } from '../../utils/event-emitter';
import { useERC721Contract } from '../erc721';
import MarketplaceMeta from './marketplace-contract';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const TEST_NFT_ADDRESS = '0x676E65596E04F340d52aFbf8b63b73750AA30D9e';
export const emitter = new EventEmitter();

const toEthNumber = (val) => {
    return val.toString() / 1000000 / 1000000 / 1000000;
};

export const useMarketplaceContract = () => {
    const { address, abi } = MarketplaceMeta;
    const { provider } = useContext(BlochainContext);
    if (!provider) return { contract: null };
    const signer = provider.getSigner();
    const MarketplaceContract = new Contract(address, abi, signer);
    const contract = MarketplaceContract.connect(signer);
    return { contract };
};

export const useBalanceInfo = () => {
    const [balance, setBalance] = useState<number>(undefined);
    const [isBalanceLoading, setBalanceIsLoading] = useState<boolean>(true);
    const { selectedAddress } = useContext(BlochainContext);
    const { contract } = useMarketplaceContract();

    useEffect(() => {
        if (!contract || !selectedAddress) return;

        if (!balance) {
            contract.escrow(selectedAddress).then((balance) => {
                setBalance(toEthNumber(balance));
                setBalanceIsLoading(false);
            });
        }
    }, [contract, selectedAddress, balance]);

    useEffect(() => {
        const unsubscribeUpdateEvent = emitter.on(EVENT_TYPE.BALANCE_CHANGE, {}, () => {
            setBalance(undefined);
        });

        return () => {
            unsubscribeUpdateEvent();
        };
    }, []);

    return { balance, isBalanceLoading };
};

export interface PriceInfoResult {
    isPriceInfoLoading: boolean;
    askPrice?: AskPrice;
    bidPrice?: BidPrice;
}

export const usePriceInfo = (nftAddress: string, tokenId: number): PriceInfoResult => {
    const [isPriceInfoLoading, setPriceInfoIsLoading] = useState<boolean>(true);
    const [askPrice, setAskPrice] = useState<AskPrice>(undefined);
    const [bidPrice, setBidPrice] = useState<BidPrice>(undefined);
    const { selectedAddress } = useContext(BlochainContext);
    const { contract } = useMarketplaceContract();

    useEffect(() => {
        if (!contract || !selectedAddress || !tokenId) return;

        if (!askPrice && !bidPrice) {
            Promise.all([
                contract.asks(nftAddress, tokenId),
                contract.bids(nftAddress, tokenId),
            ]).then(([askResponse, bidResponse]) => {
                const [askExists, seller, askPrice, to] = askResponse;
                const [bidExists, buyer, bidPrice] = bidResponse;

                setAskPrice({
                    exists: askExists,
                    seller,
                    // TBD
                    price: toEthNumber(askPrice),
                    to,
                });
                setBidPrice({
                    exists: bidExists,
                    buyer,
                    // TBD
                    price: toEthNumber(bidPrice),
                });
                setPriceInfoIsLoading(false);
            });
        }
    }, [contract, selectedAddress, tokenId, askPrice, bidPrice, nftAddress]);

    useEffect(() => {
        const unsubscribeUpdateEvent = emitter.on(EVENT_TYPE.COMPLETE, {}, (_, data) => {
            if (data.method) {
                setAskPrice(undefined);
                setBidPrice(undefined);
            }
        });

        return () => {
            unsubscribeUpdateEvent();
        };
    }, []);

    return { isPriceInfoLoading, askPrice, bidPrice };
};

const createErrorHandler = (e: Error & { code: number }) => {
    if (e.code === 4001) {
        emitter.emit(EVENT_TYPE.USER_REJECT, {}, { message: e.message });
    } else {
        emitter.emit(EVENT_TYPE.ERROR, {}, { message: e.message });
    }
};

export const createAsk =
    (contract: Contract, erc721Contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[], price: number[], toAddress: string[]) => {
        if (!contract || !selectedAddress) return;

        try {
            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.CREATE_ASK });

            if (contract.address !== nftAddress[0]) {
                const isApprovedForAll = await erc721Contract.isApprovedForAll(
                    selectedAddress,
                    contract.address,
                );

                if (!isApprovedForAll) {
                    await erc721Contract.setApprovalForAll(contract.address, true);
                }
            }

            const transaction = await contract.createAsk(
                nftAddress,
                tokenId,
                price.map((x) => toWei(String(x))),
                toAddress,
            );

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const createBid =
    (contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[], price: number[]) => {
        if (!contract || !selectedAddress) return;

        try {
            const total = price.reduce((result, num) => (result += num), 0);

            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.CREATE_BID });

            const transaction = await contract.createBid(
                nftAddress,
                tokenId,
                price.map((x) => toWei(String(x))),
                { value: toWei(String(total)) },
            );

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const cancelAsk =
    (contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[]) => {
        if (!contract || !selectedAddress) return;

        try {
            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.CANCEL_ASK });

            const transaction = await contract.cancelAsk(nftAddress, tokenId);

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const cancelBid =
    (contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[]) => {
        if (!contract || !selectedAddress) return;

        try {
            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.CANCEL_BID });

            const transaction = await contract.cancelBid(nftAddress, tokenId);

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const acceptAsk =
    (contract: Contract, erc721Contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[], price: number[]) => {
        if (!contract || !selectedAddress) return;

        try {
            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.ACCEPT_ASK });

            const nonContractNftAddress = nftAddress.filter((addr) => addr !== contract.address);
            if (nonContractNftAddress.length !== 0) {
                const isApprovedForAll = await erc721Contract.isApprovedForAll(
                    selectedAddress,
                    contract.address,
                );

                if (!isApprovedForAll) {
                    await erc721Contract.setApprovalForAll(contract.address, true);
                }
            }

            const total = price.reduce((result, num) => (result += num), 0);
            const transaction = await contract.acceptAsk(nftAddress, tokenId, {
                value: toWei(String(total)),
            });

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const acceptBid =
    (contract: Contract, erc721Contract: Contract, selectedAddress: string) =>
    async (nftAddress: string[], tokenId: number[]) => {
        if (!contract || !selectedAddress) return;

        try {
            emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.ACCEPT_BID });

            // TBD: this condition work until multiple operation not present
            if (contract.address !== nftAddress[0]) {
                const isApprovedForAll = await erc721Contract.isApprovedForAll(
                    selectedAddress,
                    contract.address,
                );

                if (!isApprovedForAll) {
                    await erc721Contract.setApprovalForAll(contract.address, true);
                }
            }

            const transaction = await contract.acceptBid(nftAddress, tokenId);

            emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
        } catch (e) {
            createErrorHandler(e);
        }
    };

export const withdraw = (contract: Contract, selectedAddress: string) => async () => {
    if (!contract || !selectedAddress) return;

    try {
        emitter.emit(EVENT_TYPE.CREATE, {}, { method: OPERATION_TYPES.WITHDRAW });

        const transaction = await contract.withdraw();

        emitter.emit(EVENT_TYPE.TRANSACTION, {}, { tnxHash: transaction.hash });
    } catch (e) {
        createErrorHandler(e);
    }
};

export interface MarketplaceActionFactoryResult {
    createAsk: ReturnType<typeof createAsk>;
    createBid: ReturnType<typeof createBid>;
    cancelAsk: ReturnType<typeof cancelAsk>;
    cancelBid: ReturnType<typeof cancelBid>;
    acceptAsk: ReturnType<typeof acceptAsk>;
    acceptBid: ReturnType<typeof acceptBid>;
    withdraw: ReturnType<typeof withdraw>;
}

export const useMarketplaceActionFactory = (
    nftAddress?: string,
): MarketplaceActionFactoryResult | undefined => {
    const { selectedAddress } = useContext(BlochainContext);
    const { contract } = useMarketplaceContract();
    const defaultNftAddress = nftAddress || contract?.address;
    const { erc721Contract } = useERC721Contract(defaultNftAddress);

    if (!contract || !selectedAddress) return undefined;

    return {
        createAsk: createAsk(contract, erc721Contract, selectedAddress),
        createBid: createBid(contract, selectedAddress),
        cancelAsk: cancelAsk(contract, selectedAddress),
        cancelBid: cancelBid(contract, selectedAddress),
        acceptAsk: acceptAsk(contract, erc721Contract, selectedAddress),
        acceptBid: acceptBid(contract, erc721Contract, selectedAddress),
        withdraw: withdraw(contract, selectedAddress),
    };
};
