// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Callback<D = any> = (event: EventEmitterEvent, data: D) => any;

export interface EventEmitterEvent {
    name: string;
    [key: string]: string | number;
}

export interface Data {
    [key: string]: string | number;
}

export interface Params {
    [key: string]: string | number;
}

export type Unsubscribe = () => void;
export type Subscription = Readonly<{ paramsKey?: string; callback: Callback | undefined }>;
export type Subscriptions = Map<string, Set<Subscription>>;

export function createSubscription(params: Params, callback: Callback): Subscription {
    return {
        callback,
        paramsKey: params && JSON.stringify(params),
    };
}

export function addEventSubscription(
    eventName: string,
    subscriptions: Subscriptions,
    subscription: Subscription,
): Unsubscribe {
    const eventSubscriptions: Set<Subscription> = subscriptions.get(eventName) || new Set();

    eventSubscriptions.add(subscription);
    subscriptions.set(eventName, eventSubscriptions);

    return function unsubscribe() {
        const eventSubscriptions: Set<Subscription> | undefined = subscriptions.get(eventName);

        if (eventSubscriptions) {
            eventSubscriptions.delete(subscription);

            if (!eventSubscriptions.size) {
                subscriptions.delete(eventName);
            }
        }
    };
}

export class EventEmitter {
    protected subscriptions: Subscriptions = new Map<string, Set<Subscription>>();

    on(eventName: string, params?: Params, callback?: Callback): Unsubscribe {
        return addEventSubscription(
            eventName,
            this.subscriptions,
            createSubscription(params, callback),
        );
    }

    off(): void {
        this.subscriptions.clear();
    }

    emit(eventName: string, params?: Params, data?: Data): void {
        const { subscriptions } = this;
        const eventSubscriptions: Set<Subscription> | undefined = subscriptions.get(eventName);

        if (!eventSubscriptions) {
            return;
        }

        const emitterEvent: EventEmitterEvent = { name: eventName };
        const paramsKeyToMatch: string | undefined = params && JSON.stringify(params);

        eventSubscriptions.forEach((subscription: Subscription) => {
            const { paramsKey, callback } = subscription;
            if (!paramsKey || paramsKey === paramsKeyToMatch) {
                callback?.(emitterEvent, data);
            }
        });

        if (!eventSubscriptions.size) {
            subscriptions.delete(eventName);
        }
    }
}
