export interface AskPrice {
    exists: boolean;
    seller: string;
    price: number;
    to: string;
}

export interface BidPrice {
    exists: boolean;
    buyer: string;
    price: number;
}

export const OPERATION_TYPES = {
    CREATE_ASK: 'createAsk',
    CREATE_BID: 'createBid',
    CANCEL_ASK: 'cancelAsk',
    CANCEL_BID: 'cancelBid',
    ACCEPT_ASK: 'acceptAsk',
    ACCEPT_BID: 'acceptBid',
    WITHDRAW: 'withdraw',
};

export const ownerOperations = [
    OPERATION_TYPES.CREATE_ASK,
    OPERATION_TYPES.CANCEL_ASK,
    OPERATION_TYPES.ACCEPT_BID,
];

export const userOperations = [
    OPERATION_TYPES.ACCEPT_ASK,
    OPERATION_TYPES.CREATE_BID,
    OPERATION_TYPES.CANCEL_BID,
];

export const OPERATION_DESCRIPTION = {
    [OPERATION_TYPES.CREATE_ASK]: 'Sell',
    [OPERATION_TYPES.CREATE_BID]: 'Make Offer',
    [OPERATION_TYPES.CANCEL_ASK]: 'Cancel Listing',
    [OPERATION_TYPES.CANCEL_BID]: 'Cancel Offer',
    [OPERATION_TYPES.ACCEPT_ASK]: 'Buy Now',
    [OPERATION_TYPES.ACCEPT_BID]: 'Accept Offer',
    [OPERATION_TYPES.WITHDRAW]: 'Withdraw',
};
